import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";

const DialPad = () => {
  const [input, setInput] = useState("");

  const handleButtonClick = (value) => {
    const newValue = input + value;
    setInput(newValue);
  };

  const onClear = () => {
    setInput("");
  };

  const handleClear = () => {
    setInput("");
    onClear();
  };

  return (
    <div style={{ textAlign: "center", padding: "10px" }}>
      <TextField
        value={input}
        onChange={(e) => setInput(e.target.value)}
        variant='standard'
        fullWidth
        inputProps={{ readOnly: true }}
        style={{ marginBottom: "20px", fontSize: "24px" }}
      />

      <Grid container spacing={1} justifyContent='center'>
        {["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].map(
          (digit) => (
            <Grid item xs={4} key={digit}>
              <Button
                variant='contained'
                onClick={() => handleButtonClick(digit)}
                sx={{
                  minWidth: "50px",
                  minHeight: "50px",
                  borderRadius: "50%",
                }}>
                {digit}
              </Button>
            </Grid>
          )
        )}
      </Grid>
      <Grid container spacing={1} justifyContent='space-between' mt={2}>
        <Grid item xs={12}>
          <Button
            variant='contained'
            onClick={handleClear}
            startIcon={<BackspaceIcon />}
            sx={{ minWidth: "90%", minHeight: "30px" }}>
            Clear
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default DialPad;
