import { useMutation } from "react-query";
import { tenantAPI } from "../../../../config/api";

const useSaveCredentials = ({ endpoint, id }) => {
  const saveConfig = async (data) => {
      
      const method = id !== undefined && data ? "PUT" : "POST";
      if (method === "POST") {
        return await tenantAPI.post(endpoint, data); 
      }
      if (method === "PUT") {
        return await tenantAPI.put(`${endpoint}${id}/`, data); 
      }
    };
  
  const { mutate, isLoading, isError, error } = useMutation(saveConfig, {
    retry: false,
    onSuccess: (response) => {
      console.log("Success:", response);
      console.log("Success:", response);
    },
    onError: (error) => {
      console.error("Error:", error);
      console.error("Error:", error);
    },
  });

  return { mutate, isLoading, isError, error };
};

export default useSaveCredentials;
