import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ImportLeads = () => {
  useEffect(() => {
    document.title = "Import Leads | CRM";
  });

  return (
    <>
      <Grid container className="p-2 px-3">
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }} variant="h5">
            Import Leads
          </Typography>
          <Typography sx={{ fontWeight: 300, color: "#616161" }} variant="p">
            Import Leads in form of CSV file directly into the CRM
          </Typography>
        </Grid>
        <Grid
          container
          className="d-flex align-items-center justify-content-between mt-3 "
          sx={{display:{xs:"grid !important", sm:"flex !important"}}}
          
        >
          <Grid
            item
            md={5}
            style={{ border: "1px solid #7FAFD6", borderRadius: "4px",width:"100%" }}
          >
            <Link
              className="py-4 px-5 d-flex flex-column align-items-center"
              to={`/settings/import-data/import-leads/fixed-fields`}
            >
              <Typography
                sx={{ fontWeight: 500, color: "#000" }}
                className="text-center"
                fontSize={16}
                variant="h6"
              >
                Import Data as per the current Fields
              </Typography>
              <Typography
                sx={{ fontWeight: 300, color: "#616161" }}
                className="text-center"
                fontSize={13}
                variant="p"
              >
                This option will allow you to import data only for existing
                fields
              </Typography>
            </Link>
          </Grid>
          {/* <Grid item sm={12} md={1} className="text-center">
          Or
          </Grid>
          <Grid
            item
            md={5}
            style={{ border: "1px solid #7FAFD6", borderRadius: "4px" }}
          >
            <Link
              className="py-4 px-5 d-flex flex-column align-items-center"
              to={`#`}
            >
              <Typography
                sx={{ fontWeight: 500, color: "#000" }}
                className="text-center"
                fontSize={16}
                variant="h6"
              >
                Import and assign Custom fields
              </Typography>
              <Typography
                sx={{ fontWeight: 300, color: "#616161" }}
                className="text-center"
                fontSize={13}
                variant="p"
              >
                When you choose this option, you will be able to assign existing
                fields to your data or create new fields for them before
                importing them into CRM
              </Typography>
            </Link>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
};

export default ImportLeads;
