import React, { useState, useEffect } from "react";
import PhoneDisabledRoundedIcon from "@mui/icons-material/PhoneDisabledRounded";
import MicOffRoundedIcon from "@mui/icons-material/MicOffRounded";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
import DialpadRoundedIcon from "@mui/icons-material/DialpadRounded";
import { IconButton } from "@mui/material";
import DialPad from "./DialPad";

export const CallToast = ({
  duration,
  endCall,
  isConnecting,
  muteCall,
  unmuteCall,
  isMuted: externalIsMuted, // Renamed to avoid confusion
  phoneNumber,
}) => {
  // Remove local isMuted state and use the prop from parent
  const [openDial, setOpenDial] = useState(false);

  // Handle mute toggle using parent state
  const handleToggleMute = () => {
    if (externalIsMuted) {
      unmuteCall();
    } else {
      muteCall();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
      {isConnecting ? (
        <p
          style={{
            animation: "blinkingText 1s infinite",
            fontSize: "18px",
          }}>
          Connecting to {phoneNumber}...
        </p>
      ) : (
        <div style={{ textAlign: "center" }}>
          <p style={{ fontSize: "14px", margin: "0 0 5px 0", color: "#666" }}>
            {phoneNumber}
          </p>
          <p style={{ fontSize: "18px", margin: 0 }}>
            {duration || "00:00:00"}
          </p>
        </div>
      )}

      {openDial && <DialPad />}

      <div style={{ display: "flex", gap: "20px", marginTop: "15px" }}>
        <IconButton
          onClick={handleToggleMute}
          aria-label={externalIsMuted ? "unmute" : "mute"}
          size='large'
          style={{
            backgroundColor: externalIsMuted ? "#f44336" : "#4CAF50",
            color: "#fff",
            transition: "background-color 0.3s ease",
          }}>
          {externalIsMuted ? (
            <MicOffRoundedIcon fontSize='medium' />
          ) : (
            <MicRoundedIcon fontSize='medium' />
          )}
        </IconButton>

        {/* Commented out as in original, keeping for reference
        <IconButton
          aria-label="dialpad"
          size="large"
          style={{ backgroundColor: "#2196F3", color: "#fff" }}
          onClick={() => setOpenDial(!openDial)}>
          <DialpadRoundedIcon fontSize="medium" />
        </IconButton>
        */}

        <IconButton
          onClick={endCall}
          aria-label='end call'
          size='large'
          style={{
            backgroundColor: "#FFCDD2",
            color: "#D32F2F",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: "#ef5350",
            },
          }}>
          <PhoneDisabledRoundedIcon fontSize='medium' />
        </IconButton>
      </div>

      <style>{`
        @keyframes blinkingText {
          0% { opacity: 1; }
          50% { opacity: 0.5; }
          100% { opacity: 1; }
        }
      `}</style>
    </div>
  );
};
