import { Button, Grid } from "@mui/material";
import React from "react";
import InputField from "../../../components/formsUI/InputField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useFetchCredentials from "./twiliohooks/useGetCredentials";
import useSaveCredentials from "./twiliohooks/useSaveCredentials";
import { toast } from "react-toastify";

const TwilioSettings = () => {
  const { data: tdata, refetch } = useFetchCredentials(
    `tportal/twillio-config/`
  );
  const { mutate } = useSaveCredentials({
    endpoint: `tportal/twillio-config/`,
    id: tdata?.uuid || undefined,
  });
  let user = JSON.parse(localStorage.getItem("userData"));

  const INITIAL_VALUES = {
    account_sid: tdata ? tdata?.account_sid : "",
    phone: tdata ? tdata?.phone : "",
    auth_token: tdata ? tdata?.auth_token : "",
    app_sid: tdata ? tdata?.app_sid : "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    account_sid: Yup.string().required("This is a required field"),
    phone: Yup.number().required("This is a required field"),
    auth_token: Yup.string().required("This is a required field"),
    app_sid: Yup.string().required("This is a required field"),
  });

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={FORM_VALIDATION}
      enableReinitialize={true}
      onSubmit={async (values) => {
        mutate(tdata ? { ...values } : { ...values, user: user?.data?.uuid }, {
          onSuccess: () => {
            tdata
              ? toast.success("Details Updated !")
              : toast.success("Details Added !");
            refetch();
          },
        });
      }}>
      {({ submitForm }) => (
        <Form>
          <Grid container direction='column'>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <InputField
                  name='account_sid'
                  label='Twilio Account SID'
                  color='secondary'
                  size='small'
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  name='auth_token'
                  label='Twilio Auth Token'
                  color='secondary'
                  size='small'
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  name='phone'
                  label='Twilio Phone'
                  color='secondary'
                  size='small'
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  name='app_sid'
                  label='Twilio App SID'
                  color='secondary'
                  size='small'
                />
              </Grid>
            </Grid>
            <Grid
              item
              padding={4}
              xs={5}
              sm={3}
              md={2}
              lg={2}
              className='d-flex justify-content-around align-items-center'>
              <Button
                variant='outlined'
                size='small'
                className='text-capitalize'
                onClick={() => submitForm()}
                sx={{
                  padding: "4px 10px !important",
                }}>
                {tdata ? "Update" : "Add"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default TwilioSettings;
