/** @format */
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useEffect, useState, useRef, useCallback } from "react";
import { Device } from "@twilio/voice-sdk";
import axios from "axios";
import getDomain from "../../utils/getDomain";
import { CallToast } from "../Layout/ivr/CallToast";

const useTwilioVoiceCall = (phoneNumber) => {
  const [device, setDevice] = useState(null);
  const [callDuration, setCallDuration] = useState("00:00:00");
  const [callStartTime, setCallStartTime] = useState(null);
  const [connection, setConnection] = useState(null);
  const [pendingCall, setPendingCall] = useState(null);
  const [isCallActive, setIsCallActive] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const intervalIdRef = useRef(null);
  const toastIdRef = useRef(null);
  const connectionRef = useRef(null);
  
  const user = JSON.parse(localStorage.getItem("userData"));

  const options = {
    iceServers: [
      { urls: "stun:stun.twilio.com:3478" },
      {
        urls: "turn:turn.twilio.com:3478",
        username: "your-turn-username",
        credential: "your-turn-credential",
      },
    ],
  };

  const updateToast = useCallback((toastId, duration) => {
    if (isCallActive && toastId) {
      toast.update(toastId, {
        render: (
          <CallToast
            duration={duration}
            endCall={endCall}
            muteCall={muteCall}
            unmuteCall={unmuteCall}
            phoneNumber={phoneNumber}
            isMuted={isMuted}
          />
        ),
        autoClose: false,
        closeButton: false,
        icon: false,
        closeOnClick: false,
      });
    }
  }, [isCallActive, isMuted, phoneNumber]);

  const startTimer = useCallback((toastId) => {
    // Clear any existing timer
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    // Set the start time
    const start = Date.now();
    setCallStartTime(start);

    // Start new timer
    intervalIdRef.current = setInterval(() => {
      const totalSeconds = Math.floor((Date.now() - start) / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      
      const formattedDuration = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

      setCallDuration(formattedDuration);
      updateToast(toastId, formattedDuration);
    }, 1000);
  }, [updateToast]);

  const stopTimer = useCallback(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    setCallDuration("00:00:00");
    setCallStartTime(null);
  }, []);

  const resetCallState = useCallback(() => {
    stopTimer();
    setIsCallActive(false);
    setIsMuted(false);
    setConnection(null);
    connectionRef.current = null;
    if (toastIdRef.current) {
      toast.dismiss(toastIdRef.current);
      toastIdRef.current = null;
    }
  }, [stopTimer]);

  const fetchTwilioToken = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PROTOCOL}${getDomain()}${"."}${
        process.env.REACT_APP_API_URL
      }/tportal/get-call-token/`,
      {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.data.token) {
      throw new Error("Error fetching Twilio token.");
    }
    return response.data.token;
  };

  const { refetch } = useQuery("get-call-token", fetchTwilioToken, {
    enabled: false,
    onSuccess: (token) => {
      const twilioDevice = new Device(token, {
        enableImprovedSignalingErrorPrecision: true,
        debug: true,
      });
      setDevice(twilioDevice);
      setupDeviceListeners(twilioDevice);
    },
    onError: (error) => {
      console.error("Error fetching Twilio token:", error);
      resetCallState();
    },
  });

  const setupDeviceListeners = useCallback((twilioDevice) => {
    twilioDevice.on("connect", () => {
      setIsCallActive(true);
    });

    twilioDevice.on("disconnect", () => {
      resetCallState();
    });
  }, [resetCallState]);

  const makeCall = async (recipient) => {
    if (!recipient) {
      console.warn("Recipient number is required.");
      return;
    }

    const toastId = `twilio-call-toast-${Date.now()}`;
    toastIdRef.current = toastId;

    toast.info(
      <CallToast
        duration="Calling..."
        endCall={endCall}
        muteCall={muteCall}
        unmuteCall={unmuteCall}
        phoneNumber={phoneNumber}
        isMuted={isMuted}
      />,
      {
        autoClose: false,
        closeButton: false,
        icon: false,
        closeOnClick: false,
        toastId,
      }
    );

    if (!device) {
      try {
        const { data: token } = await refetch();
        if (!token) {
          toast.update(toastId, { 
            render: <div>Error fetching token.</div>,
            autoClose: 3000,
          });
          resetCallState();
          return;
        }
        setPendingCall({ recipient, toastId });
        return;
      } catch (error) {
        console.error("Error fetching token:", error);
        toast.update(toastId, { 
          render: <div>Error fetching token.</div>,
          autoClose: 3000,
        });
        resetCallState();
        return;
      }
    }

    try {
      const conn = await device.connect({
        params: { To: recipient, caller_id: user.data?.uuid },
        rtcConfiguration: options,
      });

      if (!conn) {
        console.warn("Connection object is not valid.");
        resetCallState();
        return;
      }

      setConnection(conn);
      connectionRef.current = conn;

      conn.on("accept", () => {
        startTimer(toastId);
        updateToast(toastId, "00:00:00");
      });

      conn.on("error", (callError) => {
        console.error("Call error:", callError);
        toast.update(toastId, {
          render: <div>Error: {callError.message}</div>,
          autoClose: 3000,
        });
        resetCallState();
      });

      conn.on("disconnect", () => {
        resetCallState();
      });

    } catch (error) {
      console.error("Error connecting to the call:", error);
      toast.update(toastId, {
        render: <div>Error connecting to call</div>,
        autoClose: 3000,
      });
      resetCallState();
    }
  };

  const endCall = useCallback(async () => {
    if (device) {
      await device.disconnectAll();
      resetCallState();
    }
  }, [device, resetCallState]);

  const muteCall = useCallback(() => {
    if (connectionRef.current) {
      connectionRef.current.mute(true);
      setIsMuted(true);
      // Update toast to reflect mute state
      if (toastIdRef.current) {
        updateToast(toastIdRef.current, callDuration);
      }
    }
  }, [callDuration, updateToast]);

  const unmuteCall = useCallback(() => {
    if (connectionRef.current) {
      connectionRef.current.mute(false);
      setIsMuted(false);
      // Update toast to reflect mute state
      if (toastIdRef.current) {
        updateToast(toastIdRef.current, callDuration);
      }
    }
  }, [callDuration, updateToast]);

  useEffect(() => {
    if (device && pendingCall) {
      toast.dismiss();
      makeCall(pendingCall.recipient);
      setPendingCall(null);
    }
  }, [device, pendingCall]);

  useEffect(() => {
    return () => {
      resetCallState();
      if (device) {
        device.disconnectAll();
      }
    };
  }, [device, resetCallState]);

  return { 
    makeCall, 
    endCall, 
    callDuration, 
    refetch,
    isCallActive,
    isMuted 
  };
};

export default useTwilioVoiceCall;