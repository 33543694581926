/** @format */

import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import errorHandle from "../../utils/errorHandle";
import { tenantAPI } from "../../config/api";

export const useColumnPreference = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (data) => {
      return await tenantAPI.post(`accounts/column-preference/`, data);
    },
    {
      onSuccess: () => {
        toast.success("Column preferences saved successfully!");
      },
      onError: (error) => {
        // Handle error
        errorHandle(error);
      },
    }
  );

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
  };
};
